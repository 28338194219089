.header {
	position: fixed;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	height: 70px;
	background-color: transparent;
	z-index: 999;

	.header-content {
		width: 100%;
		height: 100%;
		max-width: var(--maxWidth);
		background-color: #fff;
		margin: 10px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
		box-sizing: border-box;
		border: 1px solid #e8e8e8;
		box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0/0.1), 0 2px 4px -2px rgb(0 0 0/0.1);

		.header-logo {
			font-weight: 600;
			font-size: 20px;
			cursor: pointer;
			transition: 0.1s ease-in-out;

			* {
				cursor: pointer;
			}

			&:hover {
				opacity: 0.7;
			}
		}

		.navigations {
			height: 100%;
			display: flex;
			align-items: center;

			.change-lang-container {
				margin: 0 10px;
				position: relative;

				.change-lang-btn {
					background-color: transparent;
					padding: 7px;
					border-radius: 5px;
					border: 1px solid var(--borderColor);
					font-weight: 600;
					text-transform: uppercase;
					font-size: 13px;
					cursor: pointer;
					outline: none;
					color: var(--text);
				}

				.languages-dd {
					position: absolute;
					background-color: #fff;
					width: 200px;
					right: 0;
					margin-top: 5px;
					box-shadow: 0 0 #0000, 0 0 #0000, 0 0px 6px -1px rgb(0 0 0/0.3), 0 2px 4px -2px rgb(0 0 0/0.3);
					border-radius: 5px;
					padding: 10px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;

					.language-option {
						font-weight: 600;
						text-transform: uppercase;
						background-color: transparent;
						background-color: transparent;
						border: none;
						padding: 8px 0;
						cursor: pointer;
						outline: none;
						color: var(--text);

						&:hover {
							background-color: rgba($color: #000000, $alpha: 0.1);
						}
					}
				}
			}

			.mobile-menu-btn {
				display: none;
				font-weight: 600;
				color: var(--lightText);
				cursor: pointer;
				padding: 10px;
				font-size: 30px;

				&:hover {
					color: var(--tintColor);
				}
			}

			.nav-menu {
				display: flex;
				gap: 10px;
				height: 100%;
				align-items: center;

				.close-mobile-menu-btn {
					display: none;
				}

				.nav-button {
					border: none;
					background-color: transparent;
					cursor: pointer;
					padding: 10px;
					padding-top: 12px;
					outline: none;
					font-size: 15px;
					font-weight: 600;
					color: var(--lightText);
					border-bottom: 2px solid transparent;
					transition: 0.2s ease-in-out;

					&:hover {
						color: var(--tint);
						border-bottom-color: var(--tint);
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.header {
		.header-content {
			.navigations {
				.change-lang-container {
					position: initial;
					.languages-dd {
						border-top: 1px solid var(--borderColor);
						border-bottom: 1px solid var(--borderColor);
						width: 100vw;
						left: 0;
						right: 0;
						border-radius: 0;
						box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0/0.1), 0 2px 4px -2px rgb(0 0 0/0.1);
						top: 80px;
					}
				}

				.mobile-menu-btn {
					display: block;
				}

				.nav-menu-bg {
					position: absolute;
					background-color: rgba($color: #000000, $alpha: 0.5);
					width: 0;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					opacity: 0;
					height: 100vh;
					transition: opacity 0.2s ease-in-out;

					.nav-menu {
						float: right;
						background-color: var(--onBackground);
						flex-direction: column;
						width: 400px;
						max-width: 0px;
						overflow: hidden;
						transition: 0.2s ease-in-out;
						gap: 5px;

						.close-mobile-menu-btn {
							display: block;
							align-self: flex-end;
							margin: 20px;
							font-size: 30px;
							color: var(--lightText);
							transition: 0.2s ease-in-out;
							cursor: pointer;

							&:hover {
								color: var(--text);
							}
						}

						.nav-button {
							width: 100%;
						}
					}

					&.opened {
						opacity: 1;
						width: 100%;

						.nav-menu {
							max-width: 90%;
						}
					}
				}
			}
		}
	}
}
