.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.whatsapp-sticky {
		width: 50px;
		height: 50px;
		position: fixed;
		bottom: 30px;
		right: 30px;
		z-index: 1000;
		background-color: #28d268;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		box-shadow: 0px 0px 3px 1px var(--lightText);
		cursor: pointer;
		transition: 0.2s ease-in-out;
		opacity: 0.7;

		* {
			cursor: pointer;
		}

		.whatsapp-icon {
			color: #fff;
			font-size: 30px;
			text-shadow: 1px 1px grey;
		}

		&:hover {
			transform: scale(1.05);
			opacity: 1;
		}
	}

	.app-body {
		flex: 1;

		.my-button-style {
			background-color: var(--tint) !important;
			color: #fff !important;
			height: 45px;
		}
	}
}

:root {
	--maxWidth: 1200px;
	--tint: #469bbf;
	--lightTint: #469bbf50;
	--tintLow2: #469bbf30;
	--tintLow: #469bbf20;
	--text: #000;
	--lightText: gray;
	--background: #efefef;
	--onBackground: #fff;
	--secondary: #f66608;
	--red: #d90000;
	--borderColor: #e4e4e4;
	--boxShadowColor: rgb(161 172 184 / 15%);
}
