.home-page {
	.home-bg-image {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-image: url("../../images/home_bg.jpg");
		background-position: bottom;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: -1;
	}

	.home-section {
		display: flex;
		overflow: auto;
		background-color: #f8f5ff;

		&.vertical {
			flex-direction: column;
		}

		&.center {
			text-align: center;
		}

		&.transparent {
			background-color: rgba($color: #000000, $alpha: 0.2);
			background-image: linear-gradient(-45deg, var(--lightTint), rgba($color: #ededed, $alpha: 0.1));
			animation: gradient 10s ease infinite;
			background-size: 400% 200%;
		}

		.home-section-body {
			display: flex;
			overflow: hidden;
			width: var(--maxWidth);
			margin: 50px auto;
			gap: 30px;
			padding: 0 10px;
			box-sizing: border-box;

			.contact-us-form {
				display: flex;
				flex-direction: column;
				width: 600px;
				max-width: 95%;
				background-color: #fff;
				padding: 30px;
				border: 0 solid #e5e7eb;
				box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0/0.1), 0 2px 4px -2px rgb(0 0 0/0.1);
				border-radius: 5px;
				gap: 15px;
				box-sizing: border-box;
				margin: auto;

				.accept-rights {
					font-size: 12px;
					color: var(--lightText);
					margin: 0;
				}

				.contact-thanku-message {
					font-size: 19px;
					color: var(--lightText);
					line-height: 1.5em;
					text-align: center;
				}

				.contact-us-title {
					text-align: center;
					font-size: 26px;
					font-weight: 700;
					color: var(--text);
					padding-bottom: 5px;
					width: fit-content;
					padding: 0 10px;
					margin: auto;
					margin-bottom: 10px;
					border-bottom: 3px solid var(--tintLow2);
				}

				.contact-us-input-wrapper,
				.contact-us-textarea-wrapper {
					display: flex;
					align-items: center;
					height: 45px;
					width: 100%;
					box-sizing: border-box;
					border: 1px solid var(--borderColor);
					gap: 5px;
					border-radius: 5px;
					overflow: hidden;

					.contact-us-input-icon {
						padding: 0 5px;
						color: var(--lightText);
						transition: 0.2s ease-in-out;
					}

					.contact-us-input,
					.contact-us-textarea {
						flex: 1;
						height: 100%;
						box-sizing: border-box;
						border: none;
						outline: none;
						color: var(--lightText);
						font-size: 16px;
						width: inherit;
						transition: 0.2s ease-in-out;
					}

					&:hover,
					&:focus-within {
						.contact-us-input-icon {
							color: var(--text);
						}

						.contact-us-input {
							color: var(--text);
						}
					}
				}

				.contact-us-textarea-wrapper {
					height: 100px;

					.contact-us-textarea {
						font-family: inherit;
						resize: none;
						padding: 10px;
					}
				}
			}

			&.vertical {
				flex-direction: column;
			}

			&.flip {
				flex-direction: row-reverse;
			}

			&.intro {
				padding: 0 20px;
				min-height: 20vh;
				width: 100%;
				box-sizing: border-box;

				.company-name {
					margin: auto;
					font-size: 2.5em;
					font-weight: 700;
					color: #fff;
					text-shadow: 1px 1px 5px var(--lightText);
				}

				.intro-descritpion {
					font-size: 24px;
					font-weight: 700;
					color: #fff;
					margin-top: 0;
				}
			}

			.section-left {
				flex: 1;
				display: flex;
				flex-direction: column;
				box-sizing: border-box;

				.section-pre-title {
					background-color: var(--tintLow2);
					width: fit-content;
					padding: 5px 20px;
					border-radius: 5px;
					color: var(--tint);
					font-weight: 600;
					font-size: 12px;
					align-self: flex-start;
				}

				.section-title {
					font-size: 22px;
					text-align: start;
					margin-top: 0;
				}

				.section-text-title {
					margin-bottom: 0;
					font-size: 18px;
					font-weight: 600;
					text-align: start;
				}

				.section-text-body {
					color: var(--lightText);
					text-align: start;
					margin-top: 0;
				}

				.our-services {
					border-radius: 10px;

					.our-service {
						background-color: var(--background);
						position: relative;
						padding: 20px;
						margin: 15px 0;
						box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0/0.1), 0 2px 4px -2px rgb(0 0 0/0.1);
						border-radius: 10px;

						.service-dot {
							width: 100px;
							height: 1px;
							border-radius: 10px;
							background-color: var(--tint);
						}

						.service-title {
							margin: 0;
							font-weight: 600;
							font-size: 16px;
							margin-bottom: 5px;
						}

						.service-description {
							margin: 0;
							margin-top: 15px;
							font-size: 14px;
						}
					}
				}

				.service-final {
					font-size: 15px;
					color: var(--lightText);
				}
			}

			.section-right {
				flex: 1;
				box-sizing: border-box;
				overflow: hidden;

				.section-images-row {
					display: flex;
					gap: 10px;
					align-items: flex-end;
					margin: 10px 0;

					.section-grid-image {
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	.swiper-wrapper {
		background-color: var(--background);
		display: flex;

		.swiper-container {
			flex: 1;
			max-width: var(--maxWidth);

			.swiper-card {
				height: 200px;
				display: flex;

				.company-image {
					width: 100%;
					object-position: center;
					object-fit: contain;
				}
			}
		}
	}

	.section-title-text {
		position: relative;
		font-weight: 600;
		font-size: 24px;
		margin: 0;
		margin-bottom: 20px;

		&::after {
			content: "";
			position: absolute;
			width: 50px;
			height: 2px;
			background: var(--tint);
			border-radius: 10px;
			bottom: -5px;
			right: 0;
		}
	}
}

@media only screen and (max-width: 767px) {
	.home-page {
		.home-section {
			.home-section-body {
				flex-direction: column;

				&.flip {
					flex-direction: column-reverse;

					.section-right {
						display: none;
					}
				}
			}
		}
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
