.policy-content {
	margin: 50px 15px;
	font-family: "Poppins", sans-serif;
	max-width: var(--maxWidth);
	margin: auto;
	padding: 0 10px;
	padding-top: 70px;

	.policy-title {
		font-size: 36px;
		color: var(--text);
		direction: rtl;
	}

	.policy-description {
		direction: rtl;
		color: var(--lightText);
		font-size: 16px;
	}

	.policy-content {
		direction: rtl;
		color: var(--lightText);
		font-size: 16px;
		margin: 0;

		.content-title {
			font-weight: 600;
			color: var(--text);
			margin: 30px 0;
		}

		.content-value {
			margin: 20px 0;
			line-height: 26px;
			color: var(--lightText);
		}
	}
}
