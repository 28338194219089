.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--onBackground);
	padding: 20px 0;
	box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -2px rgba(0, 0, 0, 0.1);
	border-top: 1px solid var(--borderColor);

	.footer-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: var(--maxWidth);

		.footer-navigations {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 10px;
			margin: 5px 0;

			.footer-nav-button {
				border: none;
				background-color: transparent;
				cursor: pointer;
				padding: 10px;
				outline: none;
				font-size: 15px;
				font-weight: 600;
				color: var(--lightText);
				opacity: 0.8;
				transition: 0.2s ease-in-out;

				.social-media-icon {
					font-size: 30px;
				}

				&:hover {
					color: var(--text);
					opacity: 1;
				}
			}
		}

		.copyright-wrapper {
			display: flex;
			justify-content: center;
		}

		.copyright-message {
			font-size: 15px;
			font-weight: 600;
			color: var(--lightText);
			opacity: 0.8;
		}
	}
}

@media only screen and (max-width: 767px) {
	.footer {
	}
}
